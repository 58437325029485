import React from "react";
import Theme1404 from "../themes/theme1/theme1404";
import Theme2404 from "../themes/theme2/theme2404";
import Theme3404 from "../themes/theme3/theme3404";
import Theme4404 from "../themes/theme4/theme4404";
import Theme5404 from "../themes/theme5/theme5404";
import { graphql, useStaticQuery } from "gatsby";
import WithThemeLoading from "../hoc/withThemeLoading";

function NotFoundPage(props) {
  const themeData = useStaticQuery(graphql`
    query SiteThemeNotFoundQuery {
      remarso {
        domainByURL {
          website {
            template {
              id
            }
            languages {
              language_key
            }
          }
        }
      }
    }
  `);

  let locales = []
  let currentLocale = 'en';
  let languages = themeData.remarso?.domainByURL?.website?.languages || [];
  if (languages.length > 0) {
    locales = languages.map(language => language.language_key)
  }
  
  const paths = props.path.split('/').filter(el => el);
  if (locales.indexOf(paths[0]) > -1) {
    currentLocale = paths[0];
  }

  /** Begin: Theme Switcher */
  const templateId =
    process.env.GATSBY_TEST_THEME === "0"
      ? themeData.remarso.domainByURL.website.template.id
      : process.env.GATSBY_TEST_THEME;
  const themes = [Theme1404, Theme2404, Theme3404, Theme4404, Theme5404];
  const ThemeLoading =
    parseInt(templateId) > 0
      ? WithThemeLoading(themes[parseInt(templateId) - 1])
      : WithThemeLoading(Theme1404);
  /** End: Theme Switcher */

  return <ThemeLoading locale={currentLocale} {...props} />;
}

export default NotFoundPage;
