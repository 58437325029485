/* eslint react/prop-types: 0 */
import React from "react";

import Layout from "./components/layout";
import SEO from "./components/seo";
import { translateStatic } from "../../helpers/translationHelper";
import * as theme1Styles from "../theme1/scss/theme1.module.scss";

const Theme1404 = ({ locale }) => {  
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className={theme1Styles.pageNotFound}>     
        <span className={theme1Styles.txt}>404</span>
        <h2>{translateStatic("page_not_found", locale)}</h2>
        <p>
          {translateStatic("page_doesnt_exist", locale)}<br/>
          {translateStatic("go_back_home", locale)}
        </p>
      </div>
    </Layout>
  );
};

export default Theme1404;
